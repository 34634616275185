import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const SecurityCiber = () => {

    return (
        <div>
            <PageTitle title="Política de Segurança Cibernética" />
            <div id="bodyContrato" className="text-just">
            <h5>1. Objetivo</h5>
            <p>O objetivo desta política é estabelecer as diretrizes necessárias para assegurar a confidencialidade, a integridade e a disponibilidade dos dados e sistemas de informação utilizados pela OPPO INSTITUIÇÃO DE PAGAMENTOS S/A (“Oppo Bank”).</p>

            <h5>2. Público Alvo</h5>
            <p>As disposições desta política aplicam-se: (i) a todas as instituições pertencentes a Oppo Bank, bem como aos respectivos funcionários, estagiários e aprendizes, doravante denominados “colaboradores”; (ii) às entidades e aos órgãos que possuam acesso às informações da Oppo Bank; e (iii) aos prestadores de serviços, pessoas físicas ou jurídicas, que manuseiam dados ou informações sensíveis à condução das atividades operacionais da organização.</p>

            <h5>3. Princípios de Segurança Cibernética</h5>
            <p>O processo de Segurança Cibernética do Conglomerado Prudencial, cujo objetivo é proteger as informações do negócio e clientes, é pautado pelos princípios fundamentais de:</p>
            <ul>
                <li><strong>Confidencialidade:</strong> quando o acesso à informação deve ser disponibilizado apenas para as entidades ou pessoas devidamente autorizadas pelo proprietário ou dono da informação;</li>
                <li><strong>Integridade:</strong> fato de manter a informação armazenada e trafegada com todas as suas características originais ao longo do seu ciclo de vida estabelecidas pelo proprietário ou dono da informação;</li>
                <li><strong>Disponibilidade:</strong> garantir que a informação esteja disponível para uso sempre que entidades ou pessoas autorizadas necessitem;</li>
            </ul>

            <h5>4. Diretrizes</h5>
            <p>As diretrizes estabelecem um programa de prevenção, detecção e redução de vulnerabilidades e impactos relacionados aos incidentes cibernéticos.</p>

            <h5>4.1 Informação: Importância e Proteção</h5>
            <p><strong>Classificação da informação e Governança</strong><p></p>
                A informação é um importante ativo do Oppo Bank e deve ser preservada e salvaguardada, em conformidade com suas políticas, normas, procedimentos e controles, bem como, com as leis e regulamentos sobre o tema.</p>
            <p><strong>Proteção de Dados e Privacidade</strong><p></p>
                A Oppo Bank tem o compromisso de promover a aderência às leis de privacidade de dados e de proteção financeira de seus clientes, sendo este compromisso transmitido aos seus colaboradores, contratados e prestadores de serviço.</p>

            <h5>4.2 Gestão de Identidades e de Acessos</h5>
            <p>A gestão e revisão das identidades e dos acessos aos recursos computacionais da Oppo Bank são realizados em conformidade com os requisitos descritos em Norma específica, garantindo a definição de recursos, mínimos privilégios, operações que podem ser executadas, componentes autorizados e devida rastreabilidade de acessos realizados.</p>

            <h5>4.3 Controles dos Dispositivos de Tecnologia</h5>
            <p>Os recursos de tecnologia disponibilizados pela Oppo Bank para uso dos funcionários são protegidos por controles contra ataques cibernéticos, infecções e prevenção ao vazamento de dados.</p>

            <h5>4.4 Desenvolvimento de sistemas e garantia de qualidade</h5>
            <p>A avaliação dos aspectos de segurança deve ser parte integrante no desenvolvimento de sistemas relevantes. Controles de segurança devem ser estabelecidos ao longo de toda a vida útil desses sistemas para assegurar que as informações processadas estejam protegidas, de acordo com sua classificação e exposição a risco.</p>

            <h5>4.5 Segurança e monitoramento da infraestrutura, redes e sistemas</h5>
            <p>As redes e sistemas corporativos relevantes devem ser administrados, monitorados e protegidos em consonância com as exigências e requisitos de Segurança da Informação da Oppo Bank. Devem também ser protegidos contra acessos não autorizados por meio de tecnologias de rede devidamente atualizadas, revisadas e testadas periodicamente de forma independente.</p>

            <h5>4.6 Registro e respostas de incidentes de segurança</h5>
            <p>Os incidentes de segurança cibernética relevantes são registrados, bem como deve ser realizada a análise das suas causas e dos impactos deles decorrentes. No caso da ocorrência de incidentes relevantes, serão realizadas as avaliações de adequabilidade dos controles existentes e de necessidade de criação de novos controles e, também, a contenção dos efeitos do incidente para as atividades da Oppo Bank.</p>

            <h5>4.7 Continuidade do negócio e recuperação de incidentes</h5>
            <p>O planejamento de continuidade do negócio é administrado de acordo com os requisitos estabelecidos na Política de Continuidade de Negócios e do Plano de Continuidade de Negócio para Segurança Cibernética que contempla cenários de incidentes relevantes a serem considerados nos testes de continuidade de negócios.</p>

            <h5>4.8 Gestão dos Prestadores de Serviços relevantes</h5>
            <p>Devem ser estabelecidos e continuamente aprimorados os controles de segurança cibernética destinados a assegurar que as informações tratadas pelos seus fornecedores estejam devidamente protegidas.</p>

            <h5>4.9 Avaliação de riscos cibernéticos de produtos ou serviços</h5>
            <p>Os riscos de segurança cibernética devem ser avaliados e administrados de acordo com os requisitos definidos em Norma específica e nos controles de proteção. Após o registro e análise devem ser executadas as respostas proporcionais aos riscos identificados.</p>

            <h5>4.10 Backup de Dados</h5>
            <p>A Oppo Bank deve zelar pelo processo de salvaguarda dos dados necessários para completa recuperação dos seus sistemas relevantes, a fim de atender aos requisitos operacionais e legais, assegurar a continuidade do negócio em caso de falhas ou incidentes, além de auxiliar em sua ágil recuperação.</p>

            <h5>4.11 Conscientização de Colaboradores, clientes e fornecedores</h5>
            <p>A Oppo Bank mantém um plano anual de conscientização direcionado ao desenvolvimento e manutenção das habilidades dos funcionários em relação à segurança cibernética.</p>

            <h5>5. Violações de Segurança</h5>
            <p>As violações das regras definidas nesta Política poderão ensejar a aplicação de medidas disciplinares, conforme determinam as normas de conduta do Código de Ética da Oppo Bank.</p>

            <h5>6. Canal de Comunicação</h5>
            <p>No caso de alertas de segurança e/ou incidentes, as notificações devem ser enviadas para o canal comunicação a seguir: <a href="mailto:sac@oppobank.com.br">sac@oppobank.com.br</a></p>
            </div>
        </div>
    );
};

export default SecurityCiber;