import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import "../App.css";


const Ouvidoria = () => {

    return (
        <div>
            <PageTitle title="Ouvidoria" />
            <div id="bodyContrato" className="text-just">
            <p>
            Somos uma empresa de serviços financeiros que tem como prioridade pessoas. Por isso, prezamos pelo desenvolvimento e bem-estar de quem está ao nosso redor.
            <p></p>
            A Ouvidoria da Oppo Bank tem como objetivo agir com responsabilidade e contribuir para a construção de uma empresa cada vez mais simples, pessoal e justa, com um forte propósito de fazer as pessoas e os negócios crescerem junto com a gente.
            <p></p>
            Já conversou conosco e mesmo assim não conseguiu resolver o que precisava?
            <p></p>
            Mande um e-mail para o nosso SAC descrevendo a situação para:
            <p></p>
            sac@oppobank.com.br
            </p>
            </div>
        </div>
    );
};

export default Ouvidoria;